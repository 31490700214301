.reusable-button {
    background-color:  var(--primary-blue); /* Blue background */
    color: white; /* White text */
    border: none;
    border-radius: 25px; /* Rounded edges */
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); /* Blue shadow */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    font-family: "SegoeUI-Bold";
}
@media (max-width: 900px) {
    .reusable-button {
        padding: 10px;
    }}

.reusable-button:hover {
    background-color: white; /* Change background on hover */
    color: var(--primary-white); /* Change text color on hover */
}
.reusable-button:focus {
    outline: none; /* Remove default outline */
}
.reusable-button:active {
    transform: scale(0.95); /* Flash effect on click */
}

.reusable-button-white {
    background-color: white; /* Blue background */
    color: var(--primary-blue); /* White text */
    border: none;
    border-radius: 15px; /* Rounded edges */
    padding: 20px 100px;
    margin: 20px;
    z-index: 10;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); /* Blue shadow */
    transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
    font-family: "SegoeUI-Bold";
}
@media (max-width: 1440px) {
    .reusable-button-white {
        padding: 20px 60px;
    }}

.reusable-button-white:hover {
    background-color: var(--primary-blue); /* Change background on hover */
    color: white; /* Change text color on hover */
}
.reusable-button-white:focus {
    outline: none; /* Remove default outline */
}

.reusable-button-white:active {
    transform: scale(0.95); /* Flash effect on click */
}