/* Hero Section */
.hero-dt {
    background-image: url("../../../public/images/APIpoweredDT.jpg");
    background-size: cover;
    background-position: center;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 80px 20px 0px;
}
.hero-title-dt{
    font-weight: normal;
    color: var(--primary-blue);
    font-family: "SegoeUI-Bold";
    font-size: clamp(1.2rem, 3vw, 3.5rem);
}
.hero-subtitle-dt{
    font-weight: normal;
    color: var(--primary-blue);
    font-family: "SegoeUI-Bold";
    font-size: clamp(1.2rem, 1.5vw, 3rem);
}
.hero-banner-dt {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px 50px 0 0;
    padding: 15px;
    width: 40vw;
    text-align: center;
    box-shadow: 0px -10px 10px 0px rgba(22, 23, 120, 0.5);
}

.hero-button-dt {
    margin-bottom: -30px;
    margin-top: 30px;
}
/* APIpoweredDT.css */

/* Images Section */
.images-section-dt {
    height: '120vh' ;
    font-family: "SegoeUI"!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
}

.row-content-dt {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
}

.column-dt {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-left-dt {
    max-width: 200px;
    height: auto;
}

.text-paragraph-dt {
    text-align: justify;
    font-size: clamp(1.2rem, 1.4vw, 2.5rem);
}

.spacer-dt {
    height: 10vh;
}

.blue-title-container-dt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:96vw;
    padding: 0 2vw;
}

.blue-title-dt {
    color: var(--primary-blue)!important;
    font-size: 32px;
    font-family: "SegoeUI-Bold";
}

.text-right-dt {
    color: grey; 
    font-size: 24px;
}

.image-center-dt {
    position: relative;
    width: 96vw;
    margin: 20px 0;
}
@media (min-width: 1440px) {
    .image-center-dt{
      height: 80vh;
      overflow: hidden;
    }  
}


.image-center-dt img {
    width: 100%;
    height:50vw!important;
    height: auto;
}

.overlay-text-dt {
    position: absolute;
    top: 10%;
    font-weight: bold;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 24px; /* Adjust size as needed */
}

/* Animation Section */
.animation-section-dt {
    display: flex;
    flex-direction: column;
  }


  .blue-title-dt-ani {
    color: var(--primary-blue);
    text-align: center;
    font-weight:100!important;
    font-size: 42px;
    font-family: "SegoeUI-Bold";
    max-width: 50vw;
    margin: 5vw 25vw;
  }
  @media (max-width: 1440px) {
    .blue-title-dt-ani {
      width: 70vw;
      margin: 3vw 15vw;
      max-width: 70vw;
    }}
  .blue-title-dt-ani1 {
    color: var(--primary-blue);
    text-align: center;
    font-weight:100!important;
    font-size: 42px;
    font-family: "SegoeUI-Bold";
    max-width: 60vw;
    margin: 0 20vw 15vh 20vw;
  }
  
  .animation-columns-dt {
    display: flex;
    flex-direction: column;
    height: 50vh; /* Occupy remaining height */
  }
  
  .animation-row-dt {
    display: flex;
    height: 33.33%; /* Adjust as needed for more/less rows */
  }
  
  .animation-column-dt {
    flex: 1; /* Each column takes equal space */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px; /* Adjust padding as necessary */
  }
  
  .scroll-image-dt {
    position: sticky;
    top: 0;
    width:100px;
    object-fit:cover;
    transition: transform 1s ease-in;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1); /* Solid white for overlay */
    transition: opacity 0.3s ease; /* Optional transition for smoothness */
    z-index: 1; /* Ensure overlay is above the text background */
  }
  
  /* If you want to differentiate overlays by column background color */
  .overlay-grey {
    background-color: rgba(255, 255, 255, 0.8); /* Adjust as needed */
  }
  
  .overlay-blue {
    background-color: rgba(255, 255, 255, 0.8); /* Adjust as needed */
  }
  
  .background-grey-dt {
    background-color: grey!important;
    transition: background-color 0.3s; 
    margin:10px;
    border-radius: 25px;
    font-size: 24px;
  }
  
  .background-blue-dt {
    color: white;
    background-color: var(--primary-blue)!important;
    transition: background-color 0.3s;
    margin:10px;
    border-radius: 25px  0 0 25px;
    font-size: 24px;
    font-family: "SegoeUI-Bold";
  }
  
  .text-black-dt {
    color: black;
  }
  
  .text-white-dt {
    color: white;
  }
  

/* Backgrounds */
.bg-grey-dt {
    background-color: grey;
}

.bg-blue-dt {
    background-color: var(--primary-blue);
}

/* Text Section 1 */
.text-section1-dt {
    padding: 20px;
}

.title-dt {
    text-align: center;
    margin-bottom: 50px;
}
.paragraph-dt-spaced {
    text-align: center;
    font-size: 24px;
    max-width: 50vw;
    margin: 30px 25vw;
}
.paragraph-dt {
    text-align: left;
    margin-top: 20px;
    width: 50vw;
    font-size: 24px;
    margin: 10vh 25vw;
}

@media (max-width: 1440px) {
  .paragraph-dt{
    width: 60vw;
    margin: 10vh 20vw;
  }
}
.subtitle-dt {
    text-align: center;
    margin-top: 20px;
    color: var(--secondary-blue);
    width: 50vw;
    font-weight: bolder;
    font-size: 24px;
    margin: 10vh 25vw;
}
.custom-spacing {
    margin-bottom: 30px; /* Adjust this value as needed */
}
ul.custom-spacing li{
    margin-bottom: 30px; /* Adjust this value as needed */
}
.line-spacing-dt {
    line-height: 1.6; /* More line spacing */
}

/* Text Section 2 */
.text-section2-dt {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vh;
}

.blue-background-dt {
    background-color: var(--primary-blue);
    width: 100%;
    padding: 20px 0;
}

.white-text-dt {
    color: white;
    text-align: center;
    margin-top: 20px;
    width: 50vw;
    font-size: 32px;
    font-family: "SegoeUI-Bold";
    margin: 5vh 25vw;
}

.centered-title-dt {
    color: var(--primary-blue);
    font-family: "SegoeUI-Bold";
    font-size: 30px;
    text-align: center;
    margin: 7vh 0;
}
.grid-text-dt {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two equal columns */
    grid-template-rows: repeat(4, auto); /* Four rows */
    gap: 20px;
    font-size: 18px;
    grid-template-areas:
      "item1 ."
      ". item2"
      "item3 ."
      ". item4"; /* Layout for items at positions 1, 4, 5, and 8 */
  }
  
  .grid-item-dt {
    padding: 0 14vw; /* Default padding, can be overridden in individual items */
  }
  
  .grid-item-dt:nth-child(1) {
    grid-area: item1;
  }
  
  .grid-item-dt:nth-child(2) {
    grid-area: item2;
  }
  
  .grid-item-dt:nth-child(3) {
    grid-area: item3;
  }
  
  .grid-item-dt:nth-child(4) {
    grid-area: item4;
  }
  

/* Text styles */
.text-black-dt {
    color: black;
}

.blue-bold-dt {
    color: var(--primary-blue);
    font-weight: bold;
}
.text-black-dt-center {
    color: black;
    width: 50vw;
    font-weight: bolder;
    font-size: 24px;
    margin: 0 25vw;
}
.dt-row{
    margin: 5vh 0;
}
.blue-bold-dt-center {
    color: var(--primary-blue);
    font-weight: bold;
    font-weight: bolder;
    font-size: 24px;
    width: 50vw!important;
}
