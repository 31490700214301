/* Hero Section */
.hero-dt-mob {
    background-image: url("../../../public/images/APIpoweredDT.jpg");
    background-size:cover;
    background-position: center;
    height: 150vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 0; /* Removed padding */
}

.hero-title-dt-mob {
    font-weight: normal;
    color: var(--primary-blue);
    font-family: "SegoeUI-Bold";
    font-size: 32px;
}

.hero-subtitle-dt-mob {
    font-weight: normal;
    color: var(--primary-blue);
    font-family: "SegoeUI-Bold";
    font-size: 18px;
}

.hero-banner-dt-mob {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px 50px 0 0;
    padding: 10px 20px;
    width: auto; /* Removed 40vw width to allow full width */
    text-align: center;
    box-shadow: 0px -10px 10px 0px rgba(22, 23, 120, 0.5);
    margin: 0 30px;
}

.hero-button-dt-mob {
    margin-top: 30px;
    margin-bottom: -30px
}

/* APIpoweredDT.css */
/* Images Section */
.images-section-dt-mob {
    height: 320vw;
    font-family: "SegoeUI"!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20vw;
    padding: 0 15px; /* Removed padding */
}
.image-top-small{
    margin-bottom: 30px;
}
.row-content-dt-mob {
    display: flex;
    flex-direction: column; /* Turned rows into columns */
    justify-content: center;
    align-items: center;
    max-width: 100%; /* Adjusted max-width */
}

.column-dt-mob {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Set to full width */
}

.image-left-dt-mob {
    max-width: 200px;
    height: auto;
}

.text-paragraph-dt-mob {
    text-align: justify;
    font-size: 24px;
}

.spacer-dt-mob {
    height: 20vw
}

.blue-title-container-dt-mob {
    text-align: justify;
    padding: 0; /* Removed padding */
}

.blue-title-dt-mob {
    color: var(--primary-blue)!important;
    font-size: 32px;
    width: 70%;
    margin: 0 15px;
    font-family: "SegoeUI-Bold";
}

.text-right-dt-mob {
    color: grey; 
    margin: 0 15px 15px 0;
    font-size: 24px;
    text-align: right; /* Centered text */
}

.image-center-dt-mob {
    position: relative;
    margin: 0; /* Removed margin */
}

.image-center-dt-mob img {
    width: 100%;
    height: 150vw
}

.overlay-text-dt-mob {
    position: absolute;
    top: 15px;
    font-weight: bold;
    left: 10%;
    color: white;
    font-size: 18px; /* Adjust size as needed */
}



/* Animation Section */
.animation-section-mob {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
  }
  
  .blue-title-dt-ani-mob {
    color: var(--primary-blue);
    text-align: center;
    font-weight: 100 !important;
    margin: 40px 15px;
    font-size: 42px;
    font-family: "SegoeUI-Bold";
  }
  
  
  .animation-column-mob {
    display: flex;
    flex-direction: column; /* Each column now takes its content vertically */
    align-items: center;
    justify-content: center;
    max-width: 100vw;
  }
  
  .scroll-image-mob {
    height: 200px; /* Set a fixed height for consistency */
    object-fit: cover; /* Ensures the image covers the entire area */
  }
  
  .background-grey-mob, .background-blue-mob {
    width: 100vw; /* Set to 100% to match image width */
    height: 200px; /* Set the same height as the image */
    padding: 0 ;
    font-size: 28px;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
  }
  
  .background-grey-mob {
    background-color: grey !important;
  }
  
  .background-blue-mob {
    color: white;
    background-color: var(--primary-blue) !important;
    font-family: "SegoeUI-Bold";
  }
  
  .text-black-mob {
    color: black;
  }
  
  .text-white-mob {
    color: white;
  }
  /* Backgrounds */
  .bg-grey-mob {
      background-color: grey;
  }
  
  .bg-blue-mob {
      background-color: var(--primary-blue);
  }
  
  /* Text Section 1 */
  .text-section1-mob {
      padding: 0; /* Remove padding */
  }
  
  .blue-title-mob {
      text-align: center;
      color: var(--primary-blue);
      font-size: 28px;
      font-family: "SegoeUI-Bold";
      margin: 10vw 30px; /* Adjusted for mobile */
  }
  
  .paragraph-mob {
      text-align: center;
      font-size: 24px; /* Adjusted font size for mobile */
      font-family: "SegoeUI";
      margin:40px 30px; /* Remove margin for mobile */
  }
  
  .subtitle-mob {
      text-align: center;
      margin: 30px;
      color: var(--secondary-blue);
      font-weight: bolder;
      font-size: 20px; /* Adjusted font size for mobile */
  }
  
  .custom-spacing-mob {
        text-align: start;
      margin: 20px; /* Adjust this value as needed */
  }
  
  ul.custom-spacing-mob li {
      margin-bottom: 30px; /* Adjust this value as needed */
  }
  
  .line-spacing-mob {
      line-height: 1.4; /* More line spacing */
  }
  
  /* Text Section 2 */
  .text-section2-mob {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20vw;
  }
  
  .blue-background-mob {
      background-color: var(--primary-blue);
      padding: 0 20px; /* Reduced padding for mobile */
  }
  
  .white-text-dt-mob {
      color: white;
      text-align: center;
      font-size: 28px; /* Adjusted font size for mobile */
      font-family: "SegoeUI-Bold";
      padding:15px 0 ;
  }
  
  .centered-title-mob {
      color: var(--primary-blue);
      font-family: "SegoeUI-Bold";
      font-size: 24px; /* Adjusted font size for mobile */
      text-align: center;
      margin: 10vw 4vw; /* Adjusted margin for mobile */
  }
  
  .grid-text-mob {
      display: flex; /* Change from grid to flex for column layout */
      flex-direction: column; /* Stack items vertically */
      gap: 20px; /* Space between items */
      padding: 0 30px;
  }
  
  .grid-item-mob {
      padding: 0; /* Remove padding */
      margin: 10px 0;
  }
  
  /* Text styles */
  .text-black-mob {
      color: black;
  }
  
  .blue-bold-mob {
      color: var(--primary-blue);
      font-weight: bold;
  }
  
  .text-black-center-mob {
      color: black;
      font-weight: bolder;
      margin: 0 30px 0 30px;
      font-size: 20px; /* Adjusted font size for mobile */
      text-align: start; /* Center text */
  }
  
  .dt-column-mob {
      margin:10vw 0; /* Spacing between columns */
  }
  
  .blue-bold-center-mob {
      color: var(--primary-blue);
      font-weight: bold;
  }
  