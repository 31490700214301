.contact-page-section {
    padding-top: 64px;
    padding-bottom: 64px;
    font-family: "BasicSans";
}

.contact-banner-image {
    width: 100%;
    height: auto;
}

.contact-header-container {
    width: 55vw;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.contact-header-text {
    color: var(--primary-blue);
    font-family: "SegoeUI-Bold"!important;
    font-size: 40px!important;
}

.contact-container {
    margin-top: 32px;
}
.contact-form{
    margin-top: 25px;
}
.contact-form-container {
    padding-left: 5vw;
}
.spacer{
    height:1vw;
}
.contact-form-title {
    font-family: "SegoeUI-Bold"!important;
    text-align: left;
    font-size: 32px;
}

.input-label {
    text-align: left;
    font-size: 12px;
    color: black;
}

.input-field {
    border-bottom: 2px solid gray;
    color: black;
}

.submit-button-container {
    text-align: left;
    margin-top: 24px;
}

.submit-button {
    font-size: 17px;
    border-radius: 5px;
    text-transform: none;
    background-color: var(--primary-blue);
    color: white;
    margin-top: 16px;
    padding-left: 40px;
    padding-right: 40px;
}

.submit-button:hover {
    background-color: white;
    color: var(--primary-blue);
}

.contact-info {
    text-align: center;
    
    color: var(--primary-blue);
}

.send-button {
    background-color: var(--primary-blue); /* Change background on hover */
    color: white; 
    border: none;
    border-radius: 35px; /* Rounded edges */
    padding: 20px 100px;
    margin: 100px;
    z-index: 10;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); 
    transition: color 0.3s ease, color 0.3s ease; 
    font-family: "SegoeUI-Bold";
  }
  
  .send-button:hover {
    background-color: white; /* Blue background */
    color: var(--primary-blue); /* White text */
  }
  .send-button:focus {
    outline: none; /* Remove default outline */
  }
  
  .send-button:active {
    transform: scale(0.95); /* Flash effect on click */
}