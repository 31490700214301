.about-page-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Add some padding */
    background-color: white;
    font-family: "SegoeUI";
    color: var(--primary-blue); /* Text color */
}

.about-image-top-mobile {
    width: 200vw; /* Make the top image 200vw wide */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 7vh;
    margin-top: 15vh;
    object-fit: cover; /* Ensure the image covers the area appropriately */
    position: relative; /* Position relative for z-index control */
    left: -50vw; /* Shift the image to the left to show the right side */
}

.about-image-values-mobile,
.about-image-team-mobile {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Add rounded corners */
    margin-bottom: 20px; /* Space between images and text */
}

.about-title-mobile {
    font-size: 24px; /* Title size */
    margin: 20px 0; /* Space above and below title */
    text-align: center;
    width: 70%;
    font-family: "SegoeUI-Bold";
}

.about-button-mobile {
    width: 70vw; /* Button width */
    margin: 20px 0; /* Space above and below button */
    background-color: var(--secondary-color); /* Button color */
    color: white; /* Button text color */
}

.about-header-mobile {
    font-size: 32px; /* H2 size */
    max-width: 80vw;
    font-family: 'SegoeUI-Bold';
    margin: 20px 0; /* Space above and below header */
    text-align: center; /* Center text */
}

.about-paragraph-mobile {
    font-size: 16px; /* Paragraph size */
    line-height: 1.5; /* Space between lines */
    margin: 20px 0; /* Space above and below paragraphs */
    max-width: 600px; /* Limit width for readability */
    text-align: start; /* Justify text */
    color: black;
}

.about-subtitle-mobile {
    font-family: "SegoeUI-Bold";
    font-size: 24px; /* H3 size */
    margin: 20px 0; /* Space above and below subtitles */
    text-align: center; /* Center text */
}
