.contact-page-section-mobile {
    padding-top: 64px;
    padding-bottom: 64px;
    overflow: hidden; /* Prevent overflow beyond 100vw */
}

.contact-banner-image-mobile {
    width: 200vw; /* Full width of the image */
    height: auto;
    margin-left: -50vw; /* Shift left to center the image */
    position: relative; /* Relative positioning for centering */
    left: 0; /* Shift image to the right by 50% of its width */
    object-fit: cover; /* Ensure the image covers its container */
}
.contact-header-container-mobile {
    width: 80vw; /* Adjust width for mobile */
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
}

.contact-header-text-mobile {
    color: var(--primary-blue);
}

.spacer-mobile {
    padding-top: 16px;
    padding-bottom: 16px;
}

.contact-container-mobile {
    margin-top: 32px;
}

.contact-form-container-mobile {
    padding: 0 20px; /* Adjust padding for mobile */
}

.contact-form-title-mobile {
    text-align: left;
    font-size: 24px; /* Adjust font size for mobile */
    color: black;
}

.input-label-mobile {
    text-align: left;
    font-size: 12px;
    color: black;
}

.input-field-mobile {
    border-bottom: 2px solid gray;
    color: black;
}

.send-button-mobile {
    background-color: var(--primary-blue); /* Change background on hover */
    color: white; 
    border: none;
    border-radius: 35px; /* Rounded edges */
    padding: 20px 50px;
    align-items: center;
    z-index: 10;
    font-size: 22px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); 
    transition: color 0.3s ease, color 0.3s ease; 
    font-family: "SegoeUI-Bold";
  }
  
  .send-button-mobile:hover {
    background-color: white; /* Blue background */
    color: var(--primary-blue); /* White text */
  }
  .send-button-mobile:focus {
    outline: none; /* Remove default outline */
  }
  
  .send-button-mobile:active {
    transform: scale(0.95); /* Flash effect on click */
}
.contact-info-mobile {
    text-align: center;
    color: var(--primary-blue);
}
.submit-button-container-mobile {
    text-align: center;
    margin: 40px 0;
}