
.hero-box {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    font-family: "SegoeUI";
  }
  
  .hero-image {
    width: 100%;
    height: auto;
    padding: 5vh 0;
  }
  
  .hero-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .hero-text-grid {
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
  }
  
  .hero-typography {
    margin-left: 0;
    margin-right: 0;
    color: var(--primary-blue); 
    font-family: "SegoeUI-Bold"!important;
  }
  /* Text Section */
  .text-section-box {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .text-section-container {
    width: 55vw!important;
    text-align: center;
  }
  
@media (max-width: 1440px) {
  .text-section-container{
    width: 70vw!important;
  }
}
  .text-section-content {
    width: 50vw;
    margin: 0 auto;
    margin-top: 16px; /* Equivalent of mt: 4 */
    font-size: 24px;
  }
  
  .text-section-row {
    margin-bottom: 24px; /* Equivalent of mb: 3 */
  }
  .text-section-subtitle {
    font-size: 24px!important;
    font-family: "SegoeUI-Bold"!important;
  }
  .text-section-title {
    font-size: 44px!important;
    color: var(--primary-blue); 
    font-family: "SegoeUI-Bold"!important;
  }
  
  .text-section-body {
    text-align: justify;
  }
  