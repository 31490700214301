.app-bar-mobile {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between;
    background-color: white!important;
    position: fixed;
    width: 100vw!important;
    height: 80px!important; /* Set a consistent height */
    z-index: 1000;
    padding: 0; /* Remove default padding if any */
    margin: 0; /* Remove default margin if any */
}

.logo-container-mobile {
    padding-top: 20%;
    padding-left: 5px;
}

.logo-mobile {
    padding-right: 5px;
    height: 60px;
    color: var(--primary-blue)!important;
}

.appbar-title{
    font-size: 24px;
    font-family: "SegoeUI-Bold";
    color: var(--primary-blue)!important;
}

.hamburger-icon-mobile {
    padding-top: 20%;
    width: 60px;
    height: 60px;
    color: var(--primary-blue);
    margin: 0; /* Remove any default margin */
}
