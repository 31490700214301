.navigation-page-mobile {
    background-color: var(--primary-blue);
    color: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    cursor: pointer;
    transform-style: preserve-3d;
    transform: rotateY(-90deg); /* Start hidden by flipping to the side */
    transition: transform 0.5s ease-in; /* Smooth flipping effect */
}

.navigation-page-mobile.show {
    transform: rotateY(0deg); /* Final position, fully visible */
}

.flip-card-mobile {
    perspective: 100px; /* Enables the 3D effect */
}

.flip-card-inner-mobile {
    transform-origin: center right; /* Flip from right-to-left */
}

.logo-container-mobile {
    display: flex; /* Enable flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-bottom: 20vw; /* Maintain spacing below the logo */
}

.logo-mobile {
    height: 80px;
}

.navigation-items-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20vw; 
}

.navigation-link-mobile {
    cursor: pointer;
    font-size: 24px;
    text-decoration: none;
}
