.hero-container-home-mob {
  background-image: url('../../../public/images/home-background.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 250vw;
  padding-top: 40px;
}

.text-container-home-mob{
  position: relative;
  width: 80vw!important;
  height: 120vw; /* Ensure this is sufficient height */
  background-color: white;
  color: var(--primary-blue);
  border-radius: 0 0 50px 50px;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  
  transition: transform 1s ease-in-out;
  transform: translateY(0%)!important; /* Start off-screen above */
}
.title-home-mob{
  font-size: 32px;
  text-align: center;
  font-family: "SegoeUI-Bold";
}


.hidden-banner {
  transform: translateY(0)!important; 
  
  transition: transform 1s ease-in-out;
}
.paragrap-home-mob{
  font-size: 28px;
  text-align: center;
  font-family: "SegoeUI-Bold";
}
.space {
  position: relative;
  height: 50px; /* Space between text container and bottom content */
}

.bottom-content-mobile {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align the white text and button */
  margin-top: 20px; /* Space between the container and bottom content */
}

.white-text-mobile {
  color: white;
  max-width: 80vw;
  margin: 20px 0;   
  font-size: 28px;
  text-align: center;
  font-family: "SegoeUI-Bold";
}

.white-button-mob {
  background-color: white; /* Blue background */
  color: var(--primary-blue); /* White text */
  border: none;
  border-radius: 35px; /* Rounded edges */
  padding: 10px 50px;
  margin: 20px;
  z-index: 10;
  font-size: 22px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); /* Blue shadow */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
  font-family: "SegoeUI-Bold";
}

.white-button-mob:hover {
  background-color: var(--primary-blue); /* Change background on hover */
  color: white; /* Change text color on hover */
}
.white-button-mob:focus {
  outline: none; /* Remove default outline */
}

.white-button-mob:active {
  transform: scale(0.95); /* Flash effect on click */
}
/* Reset body margin */
body {
  margin: 0; /* Remove default body margin */
  padding: 0; /* Remove default body padding */
}
/* About Section */
.about-mob {
  margin-top: 50px!important;
  width: 80vw;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0; /* Remove any default margin */
}

.about-mob h2 {
  padding-left: 3vw;
  width: 94vw;
  margin-bottom: 20px;
  font-family: "SegoeUI-Bold";
  color: var(--primary-blue);
  text-align: center;
}

.about-mob h3 {
  padding-left: 10vw;
  color: var(--primary-blue);
  margin-bottom: 10px; /* Add space above each title */
}

.about-mob p {
  text-align:start;
  align-items:start;
  font-size: 20px;
  width: 90vw; /* Allow the paragraph to occupy a reasonable width */
}

.about-column-mob {
  text-align: center;

  align-items:center;
  padding-left: 5vw;
  margin-bottom: 30px; /* Space between each column */
}

.about-icon-mob {
  margin-top: 50px;
  padding-left: 10vw;
  margin-bottom: 0;
}

.about-image-mob {
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Optional: add some rounded corners */
}

/* Container styling for What We Do section */
.what-we-do-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10vw; /* Adds spacing organically from the previous section */
  min-height: 240vw;
}

/* Central content styling */
.what-we-do-content {
  padding-left: 24px;
  padding-right: 24px;
}


/* Section Title */
.section-title {
  text-align: left;
}

/* Paragraph styling */
.section-paragraph {
  text-align: justify;
  font-size: 20px;
}

/* Section container */
.next-section {
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

/* Blue span with white text */
.blue-span-mob {
  width: 100%; /* Full width */
  background-color: var(--primary-blue); /* Blue background */
  color: white; /* White text */
  font-family: "SegoeUI-Bold";
  text-align: center; /* Center the text */
  padding: 30px; /* Add padding for the height of the span */
}

.white-text-mob {
  font-size: 6vw; /* Responsive text size */
  margin: 0 10px;
}

/* Blue text on white background */
.white-background-mob{
  width: 100vw; /* Full width */
  background-color: white; /* White background */
  text-align: center; /* Center the text */
  padding-top: 30px; /* Add padding */
}

.blue-text-mob {
  font-size: 6vw; /* Responsive text size */
  color: var(--primary-blue); /* Blue text */
  font-family: "SegoeUI-Bold";
  padding: 0 15%!important;
}

/* Circle container for the 3 circles */
.circle-container-mob {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px; /* Space between circles */
  margin: 30px 0;
}

/* Circle styling */
.circle-mob {
  width: 70vw!important;
  height: 70vw!important; 
  background-color: var(--primary-blue); /* Blue background */
  color: white; /* White text */
  border-radius: 50%; /* Make it a circle */
  display: flex;
  margin: 15px 0;
  font-family: "SegoeUI-Bold";
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  font-size: 6vw; /* Responsive text size */
  text-align: center; /* Center the text */
}
.animated-column {
  display: flex;
  flex-direction: column; /* Column layout */
  align-items: center; /* Center components horizontally */
  gap: 40px; /* 40px space between the components */
  padding: 20px; /* Optional: Add padding around the column */
  width: 100vw; /* Full width */
}

.animated-container {
  flex: 1;
  max-width: 300px;
  text-align: center;
}

.api-title{
  margin-top: 50px;
  font-size: 24px;
  width:80vw!important;
}

.scalable-container-mob {
  background-color: var(--primary-blue);
  font-family: "SegoeUI-Bold";
  text-align: center;
  color: white;
  font-size: 22px;
  border-radius: 15px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 80vw!important;
}

.scalable-container-mob-p {
  padding-bottom: 20px!important;
}
.contact-form-section-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200vw;
  flex-direction: column;
  margin-bottom: 0!important;
}

.form-container-mobile {
  background-color: var(--primary-blue);
  border-radius: 50px;
  width: 90vw;
  height: auto; /* Set height to auto to allow for content adjustment */
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-bottom: 32px; /* Increased margin to separate from footer */
}

.form-content-mobile {
  justify-content: space-evenly;
}

.form-title-mobile {
  text-align: center;
  color: white;
  font-size: 22px;
  font-family: "SegoeUI-Bold";
  margin-bottom: 40px;
}

.form-label-mobile {
  text-align: left;
}

.form-field-mobile {
  margin-top: 16px;
  margin-bottom: 16px;
}

.input-field-mobile .MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.5);
}

.submit-button-mobile {
  text-align: center;
  margin-top: 40px;
}

.request-offer-button-mobile {
  background-color: white;
  color: var(--primary-blue);
  border-radius: 20px;
  text-transform: none;
  font-size: 18px;
  padding: 16px 40px;
}

.form-footer-mobile {
  background-color: var(--primary-blue);
  width: 100vw;
  padding: 30px 0;
  text-align: center;
  margin-top: 16px; /* Added margin to separate from the form container */
  margin-bottom: 0!important;
}

.send-contact-button-mob {
  background-color: white; /* Blue background */
  color: var(--primary-blue); /* White text */
  border: none;
  border-radius: 35px; /* Rounded edges */
  padding: 20px 30px;
  margin: 10%;
  z-index: 10;
  width:80%;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); 
  transition: color 0.3s ease, color 0.3s ease; 
  font-family: "SegoeUI-Bold";
}

.send-contact-button-mob:hover {
  background-color: var(--primary-blue); /* Change background on hover */
  color: white; /* Change text color on hover */
}
.send-contact-button-mob:focus {
  outline: none; /* Remove default outline */
}

.send-contact-button-mob:active {
  transform: scale(0.95); /* Flash effect on click */
}