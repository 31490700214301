.customer-page-mobile {
    display: flex;
    flex-direction: column;
    padding: 20px; /* Add some padding */
    background-color: white;
    font-family: "SegoeUI";
    color: var(--primary-blue); /* Text color */
}
.customer-image-top-mobile {
    height: 125vw; /* Set the height */
    width: 100%; /* Make the image fill the width of the parent container */
    margin-top: 1rem; /* Space above */
    margin-bottom: 1rem; /* Space below */
    object-fit: cover; /* Ensure the image covers the area appropriately */
    object-position: 5% center; /* Focus on the right side of the image */
}

.customer-image-values-mobile,
.customer-image-team-mobile {
    width: 100%; /* Make images responsive */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Add rounded corners */
    margin-bottom: 20px; /* Space between images and text */
}

.customer-title-mobile {
    font-size: 24px!important;
    font-family: "SegoeUI-Bold"!important;
    max-width: 90vw;
    margin: -10vw 0 14vw 0!important; /* Space above and below title */
    text-align: center; /* Center text */
}

.customer-header-mobile {
    font-size: 32px; /* H2 size */
    margin: 40px 0; 
    font-family: "SegoeUI-Bold";
    text-align: center; /* Center text */
}

.customer-paragraph-mobile {
    font-size: 16px; /* Paragraph size */
    line-height: 1.5; /* Space between lines */
    margin: 10px 0; /* Space above and below paragraphs */
    max-width: 600px; /* Limit width for readability */
    text-align: justify; /* Justify text */
    color: black;
}

.customer-subtitle-mob {
    display: inline-block;
    font-size: 16px; /* H3 size */
    line-height: 1.5; 
    padding: 10px 0; /* Space above and below subtitles */
    text-align: start!important; /* Center text */
}
.customer-subtitle-mob11 {
    font-size: 16px; /* H3 size */
    line-height: 1.5; 
    margin:0!important;
    padding: 10px 0!important; /* Space above and below subtitles */
    text-align: start!important; /* Center text */
}