/* General */
body {
  margin: 0;
  font-family: 'SegoeUI'!important;
}

/* Hero Section */
/* Hero Section Container */
.hero-section-container {
  background-image: url('../../../public/images/home-background.jpg');
  background-size: cover;
  background-position: center;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 80px 20px 20px; /* Adjust padding for AppBar */
  position: relative;
}

/* Banner Styles */
.banner {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0 0 20px 20px !important;
  padding: 20px!important;
  max-width: 37vw!important;
  height: 40vh;
  text-align: center;
  transition: transform 1s ease-in-out!important;
  transform: translateY(0%)!important; /* Start off-screen above */
}
.banner h2{
  font-family: "SegoeUI-Bold";
  margin-bottom: 0;
  padding: 0;
}

.banner-home-title{
  font-family: "SegoeUI-Bold";
  color: var(--primary-blue);
  font-size: clamp(1.5rem, 2.5vw, 3rem);
}
.banner-home-p {
  padding: 0;
  margin-top: 0;
  color: var(--primary-blue);
  font-family: "SegoeUI-Bold";
  font-size: clamp(1rem, 1.5vw, 2rem);
  text-align: center;
  width: 90%;
  padding-left: 5%;
}
.hero-home-white-text{

  font-size: clamp(1.1rem, 2.5vw, 2.5rem);
  font-family: "SegoeUI-Bold";
}
.hero-section-container h4{
  font-family: "SegoeUI-Bold";
  line-height: 1.2;

  color: white;
}
.button-box {
  position: absolute; /* Move the button to any specific position inside the container */
  right: -2rem;
  top: 9rem; /* Adjust this value to move the button up */
}
@media (max-width: 1440px) {
  .button-box{
  top: 7rem;
  }
}
/* Hero Text and Button */
.hero-section-text-button {
  color: white;
  text-align: left;  
  position: absolute;
  bottom: 5%;
  margin:0!important;
  z-index: 0;
}
/* About Section */
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 25vw; /* Ensure it takes at least full screen height */
  padding: 2vh 0; /* Adds vertical padding for spacing */
}

.about h2 {
  margin: 70px 0;
  color: var(--primary-blue);
  text-align: center;
  font-size: 30px;
  font-family: 'SegoeUI-Bold' ;
}
.about h3{
color: var(--primary-blue);

font-size: 23px;
}
.about p{
  padding: 0 3vw;

  font-size: clamp(1rem,1vw, 2rem);
  text-align: justify;
}
.about-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap; /* Allows items to wrap if the screen size is small */
  gap: 20px;
}

.about-column {
  text-align: center;
  flex: 1;
  max-width: 30%; /* Ensure columns are responsive and stay within bounds */
  padding-inline: 3vw;
}

.about-icon {
  font-size: 50px;
  margin-bottom: 10px;
}

/* Container styling for What We Do section */
.what-we-do-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5vh; /* Adds spacing organically from the previous section */
  min-height: 120vh;
}

/* Central content styling */
.what-we-do-content {
  padding-left: 24px;
  padding-right: 24px;
}


/* Section Title */
.section-title {
  font-family: "SegoeUI-Bold"!important;
  font-size: 30px;
  text-align: left;
}

/* Paragraph styling */
.section-paragraph {
  text-align: justify;
  font-size: 20px;
}

.contact-form-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  margin-bottom: 0!important;
}

.form-container {
  background-color: var(--primary-blue);
  border-radius: 50px;
  width: 45vw;
  height: 32vw;
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin-bottom: 8vw;
}
@media (max-width: 1440px) {
  .form-container{
    width:50vw;
    height: 40vw;
  }
}

.form-content {
  justify-content: space-evenly;
}

.form-title {
  font-family: "SegoeUI-Bold"!important;
  font-size: 32;
  text-align: center;
  margin-bottom: 40px!important;
}

.form-label {
  text-align: left;
}

.input-field {
  margin-top: 16px;
  margin-bottom: 16px;
}

.input-field .MuiInputLabel-root {
  color: rgba(255, 255, 255, 0.5);
}

.submit-button {
  text-align: center;
  margin-top: 40px;
}

.form-footer {
  background-color: var(--primary-blue);
  width: 100%;
  padding: 30px 0;
  text-align: center;
  margin-bottom: 0!important;
}

.animated-row {
  display: flex;
  justify-content: center; /* Center components horizontally */
  align-items: flex-start!important;
  gap: 40px; /* 40px space between the components */
  padding: 20px; /* Optional: Add padding around the row */
  height: 120vh; /* Full viewport height to center vertically */
}
@media (max-width: 1440px) {
  .animated-row{
    height: 160vh;
  }
}

.animated-container {
  flex: 1; /* Allow components to grow equally */
  max-width: 300px; /* Limit the width of each component */
  text-align: center; /* Center the text inside each component */
}
.scalable-container {
  background-color: var(--primary-blue); /* Blue background */
  font-family: "SegoeUI-Bold";
  text-align: center;
  padding-left: 35vw;
  color: white; /* White text */
  font-size: 22px; /* Font size for the text */
  border-radius: 15px; /* Rounded edges */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Shadow */
  padding: 20px; /* Padding inside the container */
  width: 100%; /* Full width */
  max-width: 30vw; /* Max width */
  max-height: 30vw; /* Max height (will scale up to this size) */
  overflow: auto; /* Allow scrolling if content exceeds max height */
  box-sizing: border-box;
  margin-bottom: 100px;;
}

.scalable-container p {
  margin: 0; /* Remove default paragraph margins */
  padding-bottom: 10px; /* Extra space at the bottom of the text */
}


.api-connectivity {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
}

.api-title {
  color: var(--primary-blue);
  font-family: "SegoeUI-Bold"!important;
  text-align: center;
  padding-bottom: 100px;
  width: 50%;
}

.api-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  background-color: var(--primary-blue);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.circle-text {
  font-size: 18px!important;
  font-weight: bold;
  font-family: 'SegoeUI-bold'!important;
  color: white;
}
.contact-parent-home{
  display: flex;
  justify-content: center; /* Horizontal centering */
    
}
.send-contact-button-home {
  background-color: white; /* Blue background */
  color: var(--primary-blue); /* White text */
  border: none;
  border-radius: 15px; /* Rounded edges */
  padding: 20px 100px;
  margin-top: 100px;
  z-index: 10;
  font-size: 22px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 123, 255, 0.5); 
  transition: color 0.3s ease, color 0.3s ease; 
  font-family: "SegoeUI-Bold";
}

.send-contact-button:hover {
  background-color: var(--primary-blue); /* Change background on hover */
  color: white; /* Change text color on hover */
}
.send-contact-button:focus {
  outline: none; /* Remove default outline */
}

.send-contact-button:active {
  transform: scale(0.95); /* Flash effect on click */
}