.animated-container {
  position: relative;
  width: 20vw;
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center;
}

.rectangle {
  width: auto; /* Allow content to define width */
  background-color: white;
  padding: 10px;
  border: 1px solid #000;
  text-align: center;
  z-index: 2;
}

.dash {
  width: 100%;
  height: 2px;
  background-color: black;
  margin: 5px 0;
}

.lottie-container {
  position: relative;
  width: 200px; 
  min-height: 300px !important;
  z-index: 1; /* Behind the text */
  clip-path: inset(0); /* Initially fully visible */
  transition: clip-path 0.5s ease-in-out;
}

.lottie-container.clipped {
  clip-path: inset(100% 0 0 0); /* Simulate removal from the bottom up */
}

.paragraphs {
  position: relative;
  width: auto;
  z-index: 3; /* Above the Lottie */
  margin-top: 10px;
  padding: 1vw;
  transition: transform 0.5s ease-in-out;
}

.hover-content {
  position: relative;
  margin-top: 20px;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.hovered .lottie-container {
  clip-path: inset(100% 0 0 0); /* Start clipping Lottie from the bottom */
}

.hover-button {
  color: white; /* Set text color to white */
  background-color: var(--primary-blue); /* Set background color to blue */
  border: none; /* Remove default button border */
  border-radius: 15px; /* Rounded edges */
  font-family: "SegoeUI-Bold";
  padding: 10px 20px; /* Add padding for size */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}
.hover-url{
  color:black;
}

/* Change colors on hover */
.hover-button:hover {
  background-color: white; /* Change background to white */
  color: var(--primary-blue); /* Change text color to blue */
  border: 1px solid var(--primary-blue);
}


.hovered .rectangle,
.hovered .paragraphs {
  transform: translateY(-200px); /* Move up */
  transition: transform 0.5s ease-in-out;
}

.hovered .hover-content {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.first-row-class{
  color:black;
  font-weight: bold!important;
  font-size: 22px;
  margin: 30px 0!important;
}

.second-row-class{
  color: grey;
  font-weight: bold!important;
  font-size: 18px;
  margin: 30px 0!important;
}

.default-class{
  font-size:18px!important;
  margin: 30px 0!important;
}