.footer-container {
    background-color: var(--primary-blue);
    color: white;
    padding: 24px 0;
    height: 120px;
    margin-top: 0!important;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .social-icons a {
    margin: 0 8px;
  }
  
  .social-icon {
    width: 30px;
    height: 30px;
  }
  