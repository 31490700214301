/* Text Section */
.text-section-mob-rpa {
    padding-top: 20px;
    font-size: 20px; /* Adjust font size for mobile */
    font-family: 'SegoeUI';
    max-width: 90vw; /* Increase width for mobile */
    margin: 0 auto; /* Center the content */
}

.normal-text-mob {
    margin-top: 20px;
    text-align: left;
}

.blue-text-section-mob {
    text-align: left;
}

.primary-blue-rpa-mob {
    color: var(--primary-blue);
}

.bold-mob {
    font-size: 20px!important; /* Adjust font size for mobile */
    font-family: "SegoeUI-Bold";
}

/* Subtitle */
.subtitle-mob {
    text-align: center;
    margin: 10% 5% 0 5%;
    font-family: "SegoeUI-Bold";
    font-size: 30px;
    color: var(--primary-blue);
}

/* Image Column */
.image-column-mob {
    padding-right: 16px; /* Keep the right padding for spacing */
    display: flex;
    justify-content: flex-start; /* Align image to the left */
}

/* Image */
.image-padding-mob {
    width: 100vw; /* Increase image size for mobile */
    height: auto;
    padding-top: 32px; /* Add top padding */
    padding-bottom: 32px; /* Add bottom padding */
}

/* Text Column */
.text-column-mob {
    text-align: left;
   
    padding: 0 10vw!important; /* Adjust padding for mobile */
}

.text-box-mob {
    max-width: 90vw; /* Increase width for mobile */
    font-size: 14px; /* Adjust font size for mobile */
    margin: 0 5vw 10vw 5vw; 
}

/* Button */
.button-container-mob {
    text-align: center;
    margin-top: 50px;
}

.cta-button-mob {
    font-size: 17px;
    border-radius: 5px;
    text-transform: none;
    background-color: var(--primary-blue);
    color: white;
}

.cta-button-mob:hover {
    background-color: white;
    color: var(--primary-blue);
}

/* Hero Section */
.hero-mob {
    background-image: url("../../../public/images/rpa1.jpg");
    background-size: cover;
    background-position: center;
    height: 120vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 80px 20px 0px;
}

.hero-banner-mob {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px 50px 0 0;
    padding: 15px 20px 0 20px;
    width: 80vw; /* Set width to 80vw for mobile */
    text-align: center;
    box-shadow: 0px -10px 10px 0px rgba(22, 23, 120, 0.5);
}

.hero-button-mob {
    margin-top: 8vw;
    margin-bottom: -4vw
}

.hero-title-rpa-mob {
    font-size: 24px;
    font-family: "SegoeUI-Bold";
    color: var(--primary-blue);
}
