.text-spans-section {
    padding: 64px 0;
    font-family: "SegoeUI";
  }
  
  .text-center {
    text-align: center;
  }
  .vertical-margin{
    margin: 10% 0!important;
  }
  .vertical-margin-min{
    margin: 5% 0!important;
  }
  .primary-blue {
    font-family: "SegoeUI-Bold"!important;
    color: var(--primary-blue);
    font-size: clamp(1.5rem, 2.5vw, 3rem)!important;
    padding: 0 25%!important;
    padding-bottom: 40px;
  }
  .primary-blue1{
    font-family: "SegoeUI-Bold"!important;
    color: var(--primary-blue);
    font-size: 42px!important;
  }
  .primary-blue2{
    font-family: "SegoeUI-Bold"!important;
    color: var(--primary-blue); 
    width: 62vw!important;
    text-align: center!important;
    padding: 0 !important;
    margin: 50px 19vw!important;
  }
  .responsive-image {
    width: 100%;
    height: auto;
  }
  .responsive-image1 {
    width: 100%;
    height: auto;
    padding-bottom: 100px;
  }
  .my-4 {
    margin: 32px 0;
  }
  
  .text-spans-columns {
    font-size: 18px;
  }
  
  .text-left {
    text-align: left;
  }
  
  .text-right {
    text-align: right;
  }
  
  .px-25 {
    padding-left: 25%;
    padding-right: 25%;
  }
  
  .spacer {
    height: 100px;
  }
  
  .about-hero {
    padding: 10vh 0;
    position: relative;
  }
  
  .hero-text-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .px-4 {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .technology-section {
    padding: 0 0;
  }
  
  .tech-description {
    text-align: justify;
    width: 50%;
    margin-left: 50%;
  }
  
  .relative-container {
    position: relative;
  }
  
  .centered-button {
    text-transform: none;
    z-index: 3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)!important;
  }
  
  .para {
    padding-bottom: 40px!important;
  
  }