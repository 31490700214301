
@font-face {
  font-family: 'BasicSans';
  src: url('./fonts/BasicSans.woff') format('woff'),
       url('./fonts/BasicSans.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BasicSans-Bold';
  src: url('./fonts/BasicSans-Bold.woff') format('woff'),
       url('./fonts/BasicSans-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI';
  src: url('./fonts/SegoeUI.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-Bold';
  src: url('./fonts/SegoeUI-Bold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}