.animated-container-mob {
    position: relative;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  .animated-container {
    position: relative;
    width: 80vw;
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center;
  }
  
  .rectangle {
    width: auto;
    background-color: white;
    padding: 10px;
    border: 1px solid #000;
    text-align: center;
    z-index: 2;
  }
  
  .dash {
    width: 100%;
    height: 2px;
    background-color: black;
    margin: 5px 0;
  }
  
  .lottie-container {
    width: 200px;
    z-index: 1;
  }
  
  .paragraphs-mob {
    width: 70vw;
    z-index: 3;
    margin-top: 10px;
  }
  
  .hover-content-mob {
    margin-top: 20px;
  }

  .first-row-mob{
    color:black;
    font-weight: bold!important;
    font-size: 22px;
    margin: 30px 0!important;
  }
  
  .second-row-mob{
    color: grey;
    font-weight: bold!important;
    font-size: 18px;
    margin: 30px 0!important;
  }
  
  .default-mob{
    font-size:18px!important;
    margin: 30px 0!important;
  }
  .hover-button-mob {
    color: white;
    background-color: var(--primary-blue);
    border: none;
    border-radius: 15px;
    font-family: "SegoeUI-Bold";
    padding: 10px 20px;
    font-size: 16px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .hover-url-mob {
    color: black;
  }
  