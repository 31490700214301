/* AppBar styles */
.appbar {
  background-color: white;
  box-shadow: none;

  font-family: 'SegoeUI' !important;
}

/* Toolbar styles */
.toolbar {
  background-color: white;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* Logo styles */
.logo-container {
  display: flex;
  align-items: center;
}

.logo-image {
  margin-right: 8px;
}

.title-link {
  color: var(--primary-blue) !important;
  text-decoration: none;
}

/* Navigation button styles */
.nav-buttons {
  display: flex;
  flex-direction: column;
}

.MuiButton-root {
  font-family: 'SegoeUI-Bold' !important;
  font-weight: bold;
  font-size: 32px;
  padding: 5px 25px!important;
  margin: 5px!important;
  color: black !important;
  margin-bottom: 0;
  text-transform: none;
}

/* Ensure specificity with .active */
.MuiButton-root.active {
  background-color: var(--primary-blue);
  color: white!important;
  border-radius: 10px;
  transition: border-radius 0.5s ease;
}

/* Hover styles */
.MuiButton-root:hover {
  color: var(--primary-blue);
}
