
/* Text Section */
.text-section {
    padding-top: 8px;
    font-size: 26px;
    font-family: 'SegoeUI';
    max-width: 56vw;
    margin: 0 22vw!important;
}

.normal-text {
    text-align: left;
}

.blue-text-section {
    text-align: left;
}

.primary-blue-rpa {
    color: var(--primary-blue);
}

.bold {
    font-size: 26px!important;
    font-family: "SegoeUI-Bold";
}

/* Subtitle */
.subtitle {
    text-align: center;
    margin: 5% 0;
}

/* Image Column */
/* Image Column */
.image-column {
    padding-right: 16px; /* Keep the right padding for spacing */
    display: flex;
    justify-content: flex-start; /* Align image to the left */
}

/* Image */
.image-padding {
    width: 50vw; /* Increase image size */
    height: auto;
    padding-top: 32px; /* Add top padding */
    padding-bottom: 32px; /* Add bottom padding */
}


/* Text Column */
.text-column {
    text-align: left;
    max-width: 22vw;
    padding: 0 14vw!important;
}

.text-box {
    font-size: clamp(0.5rem, 1vw, 2rem);
}

/* Button */
.button-container {
    text-align: center;
    margin-top: 50px;
}

.cta-button {
    font-size: 17px;
    border-radius: 5px;
    text-transform: none;
    background-color: var(--primary-blue);
    color: white;
}

.cta-button:hover {
    background-color: white;
    color: var(--primary-blue);
}

/* Hero Section */
.hero-rpa {
    background-image: url("../../../public/images/rpa1.jpg");
    background-size: cover;
    background-position: center;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 80px 20px 0px;
}

.hero-banner-rpa {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px 20px 0 0;
    padding: 30px;
    width: 30vw;
    text-align: center;
    box-shadow: 0px -10px 10px 0px rgba(22, 23, 120, 0.5);
}

.hero-button-rpa {
    margin-top: 30px;
    margin-bottom: -50px;
}
